import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import axios from "axios";
import { X } from "lucide-react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#343434",
  border: "2px solid #343434",
  boxShadow: 24,
  p: 4,
};

export function EditPromoCodeModal({
  open,
  setOpen,
  currPromocode,
  currDescription,
  id,
  setPromocodes,
}) {
  const handleClose = () => setOpen(false);
  const [promocode, setPromocode] = useState(currPromocode);
  const [description, setDescription] = useState(currDescription);

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(promocode);
    const res = await axios.post("/twitter/edit-promocode", {
      promocode,
      description,
      id,
    });
    const data = await res.data;

    if (data.status === 200) {
      console.log(data.data);
      setOpen(false);
      setPromocodes((prev) => [
        ...prev.map((el) => (el._id === id ? data.data : el)),
      ]);
    }
  }

  async function handleDelete() {
    const res = await axios.post("/twitter/delete-promocode", {
      id,
    });
    const data = await res.data;

    if (data.status === 200) {
      setPromocodes((prev) => [...prev.filter((el) => el._id !== id)]);
      setOpen(false);
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ color: "white", borderRadius: "10px", position: "relative" }}
        >
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <label htmlFor="promocode" style={{ width: "100px" }}>
                Promo Code:
              </label>
              <input
                style={{
                  backgroundColor: "#343434",
                  border: "1px solid black",
                }}
                id="promocode"
                type="text"
                value={promocode}
                onChange={(e) => setPromocode(e.target.value)}
                placeholder=""
              />
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <label htmlFor="description" style={{ width: "100px" }}>
                Description:
              </label>
              <input
                name="description"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{
                  backgroundColor: "#343434",
                  border: "1px solid black",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1rem",
                gap: "2rem",
              }}
            >
              <input
                style={{
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                type="submit"
                value="Edit"
              />
              <button type="button" onClick={handleDelete}>
                Delete
              </button>
            </div>
            <span
              style={{
                position: "absolute",
                top: 5,
                right: 5,
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            >
              <X size={24} />
            </span>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
export function NewPromoCodeModal({ open, setOpen, setPromocodes }) {
  const handleClose = () => setOpen(false);
  const [promocode, setPromocode] = useState("");
  const [description, setDescription] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(promocode);
    const res = await axios.post("/twitter/insert-promocode", {
      promocode,
      description,
    });
    const data = await res.data;

    if (data.status === 200) {
      setOpen(false);
      setPromocodes((prev) => [...prev, data.data]);
      setDescription("");
      setPromocode("");
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ color: "white", borderRadius: "10px", position: "relative" }}
        >
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <label htmlFor="promocode" style={{ width: "100px" }}>
                Promo Code:
              </label>
              <input
                style={{
                  backgroundColor: "#343434",
                  border: "1px solid black",
                }}
                id="promocode"
                type="text"
                value={promocode}
                onChange={(e) => setPromocode(e.target.value)}
                placeholder=""
              />
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <label htmlFor="description" style={{ width: "100px" }}>
                Description:
              </label>
              <input
                name="description"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{
                  backgroundColor: "#343434",
                  border: "1px solid black",
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <input
                style={{
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                type="submit"
                value="Insert"
              />
            </div>
            <span
              style={{
                position: "absolute",
                top: 5,
                right: 5,
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            >
              <X size={24} />
            </span>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

export function NewBlacklistModal({ open, setOpen, setBlacklists, category }) {
  const handleClose = () => setOpen(false);
  const [blacklist, setBlacklist] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(blacklist);
    const res = await axios.post("/twitter/insert-blacklist", {
      blacklist,
      category,
    });
    const data = await res.data;

    if (data.status === 200) {
      setOpen(false);
      setBlacklists((prev) => [...prev, data.data]);
      setBlacklist("");
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ color: "white", borderRadius: "10px", position: "relative" }}
        >
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <label htmlFor="blacklist" style={{ width: "100px" }}>
                Blacklist:
              </label>
              <input
                style={{
                  backgroundColor: "#343434",
                  border: "1px solid black",
                }}
                id="blacklist"
                type="text"
                value={blacklist}
                onChange={(e) => setBlacklist(e.target.value)}
                placeholder=""
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <input
                style={{
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                type="submit"
                value="Insert"
              />
            </div>
            <span
              style={{
                position: "absolute",
                top: 5,
                right: 5,
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            >
              <X size={24} />
            </span>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

export function EditBlacklistModal({
  open,
  setOpen,
  setBlacklists,
  id,
  blacklistWord,
}) {
  const handleClose = () => setOpen(false);
  const [blacklist, setBlacklist] = useState(blacklistWord);

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(blacklist);
    const res = await axios.post("/twitter/edit-blacklist", {
      blacklist,
      id,
    });
    const data = await res.data;

    if (data.status === 200) {
      setOpen(false);
      setBlacklists((prev) => [
        ...prev.map((el) => (el._id === data.data._id ? data.data : el)),
      ]);
      setBlacklist("");
    }
  }

  async function handleDelete() {
    const res = await axios.post("/twitter/delete-blacklist", {
      id,
    });
    const data = await res.data;

    if (data.status === 200) {
      setBlacklists((prev) => [...prev.filter((el) => el._id !== id)]);
      setOpen(false);
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ color: "white", borderRadius: "10px", position: "relative" }}
        >
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <label htmlFor="blacklist" style={{ width: "130px" }}>
                Blacklist Word:
              </label>
              <input
                style={{
                  backgroundColor: "#343434",
                  border: "1px solid black",
                }}
                id="blacklist"
                type="text"
                value={blacklist}
                onChange={(e) => setBlacklist(e.target.value)}
                placeholder=""
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1rem",
                gap: "2rem",
              }}
            >
              <input
                style={{
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                type="submit"
                value="Edit"
              />
              <button type="button" onClick={handleDelete}>
                Delete
              </button>
            </div>
            <span
              style={{
                position: "absolute",
                top: 5,
                right: 5,
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            >
              <X size={24} />
            </span>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

export function NewModal({ open, setOpen, setKeywords }) {
  const handleClose = () => setOpen(false);

  const [keyword, setKeyword] = useState("");
  const [changeType, setChangeType] = useState("state");
  const [cooldown, setCooldown] = useState(0);
  const [category, setCategory] = useState("shoe");

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(keyword, changeType, cooldown, category);
    const res = await axios.post("/twitter/insert-keywords", {
      keyword,
      changeType,
      cooldown,
      category,
    });
    const data = await res.data;

    if (data.status === 201) {
      setOpen(false);
      setKeywords(data.data);
      setKeyword("");
      setChangeType("");
      setCooldown(0);
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ color: "white", borderRadius: "10px", position: "relative" }}
        >
          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <label style={{ width: "80px" }} htmlFor="keywords">
                Keyword:
              </label>
              <input
                style={{
                  backgroundColor: "#343434",
                  border: "1px solid black",
                }}
                id="keywords"
                type="text"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="dunk"
              />
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <label style={{ width: "80px" }} htmlFor="type">
                Type:
              </label>
              <select
                name="type"
                id="type"
                defaultValue="state"
                value={changeType}
                onChange={(e) => setChangeType(e.currentTarget.value)}
                placeholder="Select type"
                style={{ backgroundColor: "transparent" }}
              >
                <option value="state" style={{ backgroundColor: "black" }}>
                  State Change
                </option>
                <option value="price" style={{ backgroundColor: "black" }}>
                  Price Drop
                </option>
                <option value="restock" style={{ backgroundColor: "black" }}>
                  Restock
                </option>
              </select>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <label htmlFor="cooldown" style={{ width: "80px" }}>
                Cooldown:
              </label>
              <input
                type="number"
                name="cooldown"
                id="cooldown"
                value={cooldown === 0 ? null : cooldown}
                min="0"
                onChange={(e) => setCooldown(Number(e.target.value))}
                style={{ backgroundColor: "transparent" }}
              />
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <label style={{ width: "80px" }} htmlFor="category">
                Category:
              </label>
              <select
                name="category"
                id="category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                style={{ backgroundColor: "transparent" }}
              >
                <option value="shoe" style={{ backgroundColor: "black" }}>
                  Shoe
                </option>
                <option value="other" style={{ backgroundColor: "black" }}>
                  Other
                </option>
              </select>
            </div>

            <div>
              <input
                style={{
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  marginTop: "6px",
                  width: "100%",
                }}
                type="submit"
                value="Insert"
              />
            </div>
          </form>
          <span
            style={{
              position: "absolute",
              top: 5,
              right: 5,
              cursor: "pointer",
            }}
            onClick={() => setOpen(false)}
          >
            <X size={24} />
          </span>
        </Box>
      </Modal>
    </div>
  );
}

export default function UpdateModal({
  open,
  setOpen,
  product,
  setGlobalKeywords,
}) {
  const handleClose = () => setOpen(false);
  const [keywords, setKeywords] = useState(product.keywords);
  const [changeType, setChangeType] = useState(product.changeType);
  const [cooldown, setCooldown] = useState(product.cooldown);

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(keywords);
    const res = await axios.post("/twitter/update-keywords", {
      keywords,
      id: product.id,
      keywordId: product.keywordsId,
      changeType,
      cooldown,
    });
    const data = await res.data;

    if (data.status === 200) {
      setOpen(false);
      setGlobalKeywords(data.data);
    }
  }

  async function handleDelete() {
    const res = await axios.post("/twitter/delete-keywords", {
      id: product.id,
      keywordId: product.keywordsId,
    });

    const data = await res.data;

    if (data.status === 200) {
      setGlobalKeywords(data.data);
      setOpen(false);
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{ color: "white", borderRadius: "10px", position: "relative" }}
        >
          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <label htmlFor="keywords" style={{ width: "80px" }}>
                Keyword:
              </label>
              <input
                style={{
                  backgroundColor: "#343434",
                  border: "1px solid black",
                }}
                id="keywords"
                type="keywords"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
              />
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <label style={{ width: "80px" }} htmlFor="type">
                Type:
              </label>
              <select
                name="type"
                id="type"
                value={changeType}
                onChange={(e) => setChangeType(e.currentTarget.value)}
                placeholder="Select type"
                style={{ backgroundColor: "transparent" }}
              >
                <option value="state" style={{ backgroundColor: "black" }}>
                  State Change
                </option>
                <option value="price" style={{ backgroundColor: "black" }}>
                  Price Drop
                </option>
                <option value="restock" style={{ backgroundColor: "black" }}>
                  Restock
                </option>
              </select>
            </div>

            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <label htmlFor="cooldown" style={{ width: "80px" }}>
                Cooldown:
              </label>
              <input
                type="number"
                name="cooldown"
                id="cooldown"
                value={cooldown === 0 ? null : cooldown}
                min="0"
                onChange={(e) => setCooldown(Number(e.target.value))}
                style={{ backgroundColor: "transparent" }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1.2vmin",
                gap: "2rem",
              }}
            >
              <input style={{ cursor: "pointer" }} type="submit" value="Edit" />

              <button
                onClick={handleDelete}
                style={{ display: "flex", alignItems: "center" }}
                type="button"
              >
                Delete
              </button>
            </div>
          </form>
          <span
            style={{
              position: "absolute",
              top: 5,
              right: 5,
              cursor: "pointer",
              zIndex: 10,
            }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <X size={24} />
          </span>
        </Box>
      </Modal>
    </div>
  );
}
